import { css } from 'styled-components';
import breakPoints from '../../../../themes/breakpoints';
var styles = css(["display:flex;justify-content:space-around;flex-direction:column;.splitCtas{display:flex;padding:15px 17px;border-bottom:1px solid #eaeaea;justify-content:center;}.nonSplit{display:flex;padding:17px;justify-content:space-around;@media (max-width:375px){padding:17px 5px;align-items:flex-start;}}.emergencyBtn{background:#f00;border:2px solid #5c85aa;svg{width:20px;margin-right:10px;path{fill:#fff;stroke:#bcbcbc;}}}.link-CTA{font-size:var(--sc-font-size-default,", ");line-height:var(--sc-line-height-h4,", ");letter-spacing:var(--sc-letter-spacing-default,", ");color:var(--sc-header--bar-color,", ");vertical-align:initial;@media (max-width:375px){letter-spacing:0.1px;}}.details-CTA{font-size:var(--sc-font-size-h5,", ");line-height:var(--sc-line-height-h4,", ");letter-spacing:var(--sc-letter-spacing-h5,", ");vertical-align:top;color:var(--sc-header--bar-color,", ");.nav-btn{padding:15px 30px;@media (min-width:", "){padding:15px 40px;}}}svg{min-width:16px;width:16px;height:16px;margin:0 10px;path{fill:var(--sc-header--bar-color,", ");stroke:none;}}&.burger-open{padding-top:30px;flex-direction:column;text-align:center;.nonSplit{display:block;}.details-CTA{a{width:70%;text-align:center;@media (max-width:415px){letter-spacing:0.3px;padding:15px;}}}.link-CTA{display:none;}.map{order:2;margin-bottom:20px;a{max-width:210px;display:flex;margin:0 auto;}svg{margin-top:5px;}}.call{order:1;margin-bottom:20px;a{display:flex;justify-content:center;align-items:center;padding-left:0;}svg{margin-top:0;}}.sms{border-left:none;}.book,.pharmacyCta{order:3;border-right:none;border-left:none;margin-bottom:5px;width:100%;> a{display:none;}}}&.burger-close{.details-CTA{display:none;}.border{display:block;border-right:1px solid var(--sc-header--border-color,", ");}.map,.call,.book,.pharmacyCta,.sms{a{display:flex;align-items:flex-start;}svg{margin-top:2px;}}}@media (min-width:", "){display:none;}"], function (props) {
  var _props$theme;
  return (_props$theme = props.theme) === null || _props$theme === void 0 ? void 0 : _props$theme.fontSizeDefault;
}, function (props) {
  var _props$theme2;
  return (_props$theme2 = props.theme) === null || _props$theme2 === void 0 ? void 0 : _props$theme2.lineHeightH4;
}, function (props) {
  var _props$theme3;
  return (_props$theme3 = props.theme) === null || _props$theme3 === void 0 ? void 0 : _props$theme3.letterSpacingDefault;
}, function (props) {
  var _props$theme4, _props$theme4$header;
  return (_props$theme4 = props.theme) === null || _props$theme4 === void 0 ? void 0 : (_props$theme4$header = _props$theme4.header) === null || _props$theme4$header === void 0 ? void 0 : _props$theme4$header.barColor;
}, function (props) {
  var _props$theme5;
  return (_props$theme5 = props.theme) === null || _props$theme5 === void 0 ? void 0 : _props$theme5.fontSizeH5;
}, function (props) {
  var _props$theme6;
  return (_props$theme6 = props.theme) === null || _props$theme6 === void 0 ? void 0 : _props$theme6.lineHeightH4;
}, function (props) {
  var _props$theme7;
  return (_props$theme7 = props.theme) === null || _props$theme7 === void 0 ? void 0 : _props$theme7.letterSpacingH5;
}, function (props) {
  var _props$theme8, _props$theme8$header;
  return (_props$theme8 = props.theme) === null || _props$theme8 === void 0 ? void 0 : (_props$theme8$header = _props$theme8.header) === null || _props$theme8$header === void 0 ? void 0 : _props$theme8$header.barColor;
}, breakPoints.tabletP, function (props) {
  var _props$theme9, _props$theme9$header;
  return (_props$theme9 = props.theme) === null || _props$theme9 === void 0 ? void 0 : (_props$theme9$header = _props$theme9.header) === null || _props$theme9$header === void 0 ? void 0 : _props$theme9$header.barColor;
}, function (props) {
  var _props$theme10, _props$theme10$header;
  return (_props$theme10 = props.theme) === null || _props$theme10 === void 0 ? void 0 : (_props$theme10$header = _props$theme10.header) === null || _props$theme10$header === void 0 ? void 0 : _props$theme10$header.borderColor;
}, breakPoints.desktop);
export default styles;