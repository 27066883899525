var __jsx = React.createElement;
import React, { useState, useEffect } from 'react';
import { withTheme } from 'styled-components';
import styled from 'styled-components';
import styles from './Mailchimp.style';
import dynamic from 'next/dynamic';
var Mailchimp = function Mailchimp(_ref) {
  var className = _ref.className,
    style = _ref.style,
    type = _ref.type,
    analyticsType = _ref.analyticsType,
    analyticsValue = _ref.analyticsValue,
    theme = _ref.theme,
    mailchimpdata = _ref.mailchimpdata;
  return __jsx("div", {
    className: "nva-mailchimp ".concat(className)
  }, function () {
    if (mailchimpdata !== null && mailchimpdata !== void 0 && mailchimpdata.jotFormLink) {
      var JotFormReact = dynamic(function () {
        return import('jotform-react');
      }, {
        ssr: false,
        loadableGenerated: {
          webpack: function webpack() {
            return [require.resolveWeak('jotform-react')];
          }
        }
      });
      return __jsx(JotFormReact, {
        src: mailchimpdata === null || mailchimpdata === void 0 ? void 0 : mailchimpdata.jotFormLink
      });
    }
    if (mailchimpdata.embedCode.toLowerCase().includes('timely-script')) {
      //If script is from time.ly always will have same structure, add defer tag after <script to avoid mismatch client-server
      var scriptWithDefer = mailchimpdata.embedCode.slice(0, 8) + 'defer ' + mailchimpdata.embedCode.slice(8);
      return __jsx("div", {
        dangerouslySetInnerHTML: {
          __html: scriptWithDefer
        }
      });
    } else {
      return __jsx("div", {
        dangerouslySetInnerHTML: {
          __html: mailchimpdata.embedCode
        }
      });
    }
  }());
};
Mailchimp.defaultProps = {
  className: '',
  analyticsType: '',
  analyticsValue: ''
};
var styledMailchimp = styled(Mailchimp).withConfig({
  componentId: "sc-1nfqlpl-0"
})(["", ";"], styles);
export default withTheme(styledMailchimp);
export { Mailchimp as MailchimpVanilla };